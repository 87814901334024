import classNames from 'classnames';
import type { PropsWithChildren } from 'react'

type TitleProps = {
  className?: string;
  gradient?: boolean;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}

export const Title = ( { children, gradient = true, className, headingLevel }: PropsWithChildren<TitleProps> ) => {
  const Element : any = headingLevel ? `h${headingLevel}` : 'div';
  
  return (
    <Element className={ 'font-onest text-4xl lg:text-5xl font-semibold text-center' }>
      <span className={ classNames(
        'bg-clip-text text-transparent drop-shadow-[0_4px_4px_rgba(25,14,61,0.30)]',
        gradient ? 'bg-gradient-to-r from-[#54D8DC] via-[#58B9FF] to-[#7C79FE]' : 'bg-white',
        className
      ) }>
        { children }
      </span>
    </Element>
  )
}
